export const applicationConstants = {
    ANTILOC_REQUEST: 'APPLICATION_ANTILOC_REQUEST',
    ANTILOC_SUCCESS: 'APPLICATION_ANTILOC_SUCCESS',
    ANTILOC_FAILURE: 'APPLICATION_ANTILOC_FAILURE',      
};

export const jobApplicationEditStatus = {
    NA: 0,
    REQUESTED: 1,
    APPROVED: 2
};